*,
*::before,
*::after {
    box-sizing: border-box;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.comicBubble:after {
    content: '' !important;
    position: absolute !important;
    border-style: solid !important;
    /* reduce the damage in FF3.0 */
    display: block !important;
    width: 0 !important;
    top: -15px !important;
    right: 50px !important;
    bottom: auto !important;
    left: auto !important;
    border-width: 0 15px 15px !important;
    border-color: #fefefe transparent !important;
}

.triangle-isosceles {
    position: relative;
    padding: 15px 15px 0 15px;
    margin: 0.05em 0 0.05em;
    color: #000;
    background: #fefefe; /* default background for browsers without gradient support */
    /* css3 */
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}

.triangle-isosceles:after {
    content: '';
    position: absolute;
    border-style: solid;
    /* reduce the damage in FF3.0 */
    display: block;
    width: 0;
    top: -15px; /* value = - border-top-width - border-bottom-width */
    left: 18px; /* controls horizontal position */
    bottom: auto;
    right: auto;
    border-width: 0 15px 15px; /* vary these values to change the angle of the vertex */
    border-color: #f6f6f6 transparent;
}

/* Quest. user link copy success message */
.MuiSnackbarContent-message-mobile {
    width: 85% !important;
}
.MuiSnackbarContent-message-desktop {
    width: 310px !important;
}

body #font-size-fix {
    font-size: 18px !important;
}

/* fix responsabile */

.MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']
    .MuiAutocomplete-input {
    padding: 18.5px 0 18.5px 14px !important;
}

/* Fix textField adornment padding */
body .MuiOutlinedInput-adornedEnd {
    padding: 0 !important;
}

/* Custom scenario tables scollbar paddings bug fix */
/* width */
.scenario-scrollbar::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}

/* Track */
.scenario-scrollbar::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
.scenario-scrollbar::-webkit-scrollbar-thumb {
    background: transparent;
}

/* Handle on hover */
.scenario-scrollbar::-webkit-scrollbar-thumb:hover {
    background: transparent;
}

/* Scenario tables scoll mask + firefox */
.masked-overflow {
    --scrollbar-width: 0px;
    --mask-height: 6px;

    --mask-image-content: linear-gradient(
        to bottom,
        transparent,
        black var(--mask-height),
        black calc(100% - var(--mask-height)),
        transparent
    );

    --mask-size-content: calc(100% - var(--scrollbar-width)) 100%;
    --mask-image-scrollbar: linear-gradient(black, black);
    --mask-size-scrollbar: var(--scrollbar-width) 100%;

    mask-image: var(--mask-image-content), var(--mask-image-scrollbar);
    mask-size: var(--mask-size-content), var(--mask-size-scrollbar);
    mask-position: 0 0, 100% 0;
    mask-repeat: no-repeat, no-repeat;
}
